import Axios from 'axios';

export default async function updateAvatar({ formData, URI }) {
  const token = sessionStorage.getItem('token');

  const callback = Axios.put(URI, formData, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data'
    }
  })
    .then((response) => response)
    .catch((error) => error.response);

  return callback;
}
