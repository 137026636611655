/* eslint-disable no-unused-vars */
import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { FaUpload } from 'react-icons/fa';
import updateAvatar from 'services/updateAvatar';
import Message from './Message';
import './UpdateAvatar.css';

export default function UpdateAvatar() {
  const { user } = useSelector((state) => state.user);
  const [avatar, setAvatar] = useState(user.avatar);
  const [message, setMessage] = useState('');

  // bond the avatar image to the hidden input
  const avatarInputRef = useRef(null);
  const avatarImgRef = () => {
    avatarInputRef.current.click();
  };

  const handleOnSubmit = (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append('avatar', avatar);

    updateAvatar({
      formData,
      URI: `${process.env.REACT_APP_API_URL}/user/${user._id}`
    })
      .then(() => {
        setMessage(
          `Avatar uploaded, it may take minutes to reflect the new avatar. 
          You're free to hang out on the forum. Thanks for your patience. 
          We're working on optimizing this!`
        );
      })
      .catch(() => {
        setMessage('Some errors occured, please try again later');
      });
  };

  return (
    <div className="update_avatar">
      <button
        type="button"
        className="update_avatar--image"
        onClick={avatarImgRef}
      >
        <img src={avatar} alt={avatar.name} />
      </button>
      <form onSubmit={handleOnSubmit}>
        <input
          type="file"
          accept="image/*"
          name="avatar"
          onChange={(event) => setAvatar(event.target.files[0])}
          ref={avatarInputRef}
          style={{ display: 'none' }}
        />
        <button type="submit">
          <FaUpload /> &nbsp; UPLOAD
        </button>
      </form>
      <Message>{message}</Message>
    </div>
  );
}
