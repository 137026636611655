import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import formatDate from 'utils/formateDate';
import './User.css';

export default function User() {
  const { user } = useSelector((state) => state.user);

  return (
    <section className="profile--user">
      <div className="profile--user--info">
        <div className="profile--user--info--visual">
          <img src={user.avatar} alt="avatar" />
          <button
            type="submit"
            className="profile--user--info--visual--edit_button"
          >
            <Link to="/edit-profile">Edit Profile</Link>
          </button>
        </div>
        <div className="profile--user--info--texts">
          <h3>{user.username}</h3>
          <p className="profile--user--info--texts--immutable">{user.email}</p>
          <p className="profile--user--info--texts--immutable">
            Joined on {formatDate(user.createdAt)}
          </p>
          <div className="profile--user--info--texts--tags">
            <p className="profile--user--info--texts--tags--discord">
              {user.discordTag}
            </p>
            <p className="profile--user--info--texts--tags--game">
              {user.gameId}
            </p>
          </div>
          <p className="profile--user--info--texts--description">
            {user.descriptions}
          </p>
        </div>
      </div>
    </section>
  );
}
