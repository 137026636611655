import {
  USER_LOGIN_START,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGOUT,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL
} from '../actionTypes';

const initialState = {
  loading: false,
  isLogIn: false,
  user: {
    username: 'guest',
    avatar: 'default',
    role: 0
  },
  error: null
};

export default function user(state = initialState, action) {
  switch (action.type) {
    case USER_LOGIN_START:
      return {
        ...initialState,
        loading: true
      };

    case USER_LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        isLogIn: true,
        user: action.user
      };

    case USER_LOGIN_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case USER_LOGOUT:
      return { ...initialState, isLogIn: false };

    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        user: action.user
      };

    case UPDATE_USER_FAIL:
      return {
        ...state,
        error: action.user
      };

    default:
      return state;
  }
}
