import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useRouteMatch } from 'react-router-dom';
import _ from 'lodash';

import getCategoryData from 'store/actions/forum/getCategoryData.action';
import formateDate from 'utils/formateDate';
import { PageBanner, ListTable } from 'components';
import './ForumPage.css';
import bg from 'assets/images/Screenshot_58.png';

import PropTypes from 'prop-types';

const tableData = {
  colGroup: ['', '0', '0'],
  header: ['channel', 'posts', 'last post']
};

const ChannelList = ({ channels, currentTab }) => {
  const { url } = useRouteMatch();

  if (!_.isEmpty(channels)) {
    return channels.map(({ category, channel, lastPost, postsCount }) => {
      const transformedCategory = category.toLowerCase().replace(' ', '-');
      const transformedChannel = channel.toLowerCase().replace(' ', '-');
      const to = `${transformedCategory}/${transformedChannel}`;
      const post = useMemo(
        () => formateDate?.(lastPost?.updatedAt) ?? 'N/A',
        [lastPost]
      );

      return (
        <tr
          className={`list-table__item ${
            category === currentTab ? '' : 'hidden'
          }`}
        >
          <td>
            <Link to={`${url}/${to}`}>{channel}</Link>
          </td>
          <td>{postsCount}</td>
          <td>{post}</td>
        </tr>
      );
    });
  }
  return <></>;
};

ChannelList.propTypes = {
  channels: PropTypes.arrayOf(PropTypes.object).isRequired,
  currentTab: PropTypes.string.isRequired
};

export default function ForumPage() {
  const { isLoading, categories, error } = useSelector(
    (state) => state.category
  );
  const dispatch = useDispatch();
  const [currentTab, setCurrentTab] = useState('');

  useEffect(() => {
    dispatch(getCategoryData());
  }, []);

  const tabs = useMemo(() => {
    const newArr = [];
    categories.forEach(({ _id }) => {
      newArr.push(_id);
    });
    newArr.sort();
    setCurrentTab(newArr[0]);
    return newArr;
  }, [categories]);

  const handleTabItemClick = (tab) => {
    setCurrentTab(tab);
  };

  return (
    <section className="forum-page">
      <PageBanner title="Forum" bg={bg} />
      <section className="page__content">
        <div className="tab-menu">
          {tabs &&
            tabs.map((tab) => (
              <button
                type="button"
                className={`tab-menu__item ${
                  currentTab === tab ? 'active' : ''
                }`}
                onClick={() => handleTabItemClick(tab)}
              >
                {tab}
              </button>
            ))}
        </div>
        <ListTable tableData={tableData} error={error} isLoading={isLoading}>
          {categories?.map(({ channels }) => (
            <ChannelList channels={channels} currentTab={currentTab} />
          ))}
        </ListTable>
      </section>
    </section>
  );
}
