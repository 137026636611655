import React from 'react';
import PropTypes from 'prop-types';

import './ErrorMessage.css';

export default function ErrorMessage({ children }) {
  return <p className="form--fail">{children}</p>;
}

ErrorMessage.propTypes = {
  children: PropTypes.string.isRequired
};
