import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { FaCaretDown, FaCaretUp } from 'react-icons/fa';
import { SvgAccount } from 'assets/icons';
import logoutUser from 'store/actions/auth/logout.action';
import './Account.css';

export default function Account() {
  const { user } = useSelector((state) => state.user);
  const history = useHistory();
  const dispatch = useDispatch();
  const [isHidden, setIsHidden] = useState(true);

  const toggleDropdown = () => {
    setIsHidden(!isHidden);
  };

  const toProfilePage = () => {
    toggleDropdown();
    history.push('/profile');
  };

  const logout = () => {
    dispatch(logoutUser());
  };

  return (
    <section className="account">
      <button
        className="account__dropdown-btn"
        type="button"
        onClick={toggleDropdown}
      >
        <SvgAccount className="account__icon" />
        <span>{user.username}</span>
        {!isHidden ? <FaCaretUp /> : <FaCaretDown />}
      </button>
      <div
        className={`account__dropdown ${
          isHidden ? 'account__dropdown-close' : 'account__dropdown-open'
        }`}
      >
        <button type="button" onClick={toProfilePage}>
          Profile
        </button>
        <button type="button" onClick={logout}>
          Logout
        </button>
      </div>
    </section>
  );
}
