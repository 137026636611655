import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import _ from 'lodash';
import Joi from 'joi-browser';

import { ErrorMessage, TextField, SubmitButton } from 'components/Form';
import loginUser from 'store/actions/auth/login.action';
import './LoginPage.css';

export default function LoginPage() {
  const { loading, isLogIn, error } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const history = useHistory();
  const [formError, setFormError] = useState('');
  const [isReady, setIsReady] = useState(false);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const controlledInputs = [
    {
      label: 'Email:',
      id: 'email',
      placeholder: 'Your Email Address',
      state: email,
      setState: setEmail,
      schema: Joi.string().email()
    },
    {
      label: 'Password:',
      type: 'password',
      id: 'password',
      placeholder: 'Your Password',
      state: password,
      setState: setPassword,
      schema: Joi.any()
    }
  ];

  // Validate Form
  const validateForm = () => {
    let isValid = true;

    controlledInputs.forEach((input) => {
      const { state, schema } = input;
      const result = Joi.validate(state, schema);
      if (!_.isEmpty(result.error)) isValid = false;
    });
    return isValid;
  };

  // Submit Form
  const handleLoginFormSubmission = (event) => {
    event.preventDefault();

    if (validateForm()) {
      setFormError('');
      dispatch(loginUser({ email, password }));
    } else {
      setFormError('Not all fields are valid!');
    }
  };

  // Manage form submit button status
  useEffect(() => {
    controlledInputs.forEach((input) =>
      _.isEmpty(input.state) ? setIsReady(false) : setIsReady(true)
    );
  }, [controlledInputs]);

  // Handle page redirection if user is logged in
  useEffect(() => {
    if (isLogIn) history.push('/logged-in');
  }, [isLogIn]);

  // Disable submit button on error
  useEffect(() => {
    if (error) {
      setIsReady(false);
      setFormError(error);
    }
  }, [error]);

  return (
    <section className="login">
      <h2 className="login--title">Login</h2>
      <form className="login--form" onSubmit={handleLoginFormSubmission}>
        {formError && <ErrorMessage>{formError}</ErrorMessage>}
        {controlledInputs &&
          controlledInputs.map((input) => (
            <TextField targetInput={input} isInvalid={formError && true} />
          ))}
        <SubmitButton disabled={!isReady}>
          {loading ? 'Loading...' : 'Login'}
        </SubmitButton>
      </form>

      <div className="login--options">
        <p>
          {"Don't have an account yet? "}
          <NavLink to="/sign-up">Sign up!</NavLink>
        </p>
        <NavLink to="/forgot-password">Forgot your password?</NavLink>
      </div>
    </section>
  );
}
