import React from 'react';
import { Banner, Introduction, Special, JoinUs } from './components';
import './HomePage.css';

export default function HomePage() {
  // const props = { isNewPost: true, replyToID: '', replyToTitle: '' };
  return (
    <section className="homepage">
      <Banner />
      <Introduction />
      <Special />
      <JoinUs />
    </section>
  );
}
