import React from 'react';
import PropTypes from 'prop-types';

export default function Message({ children }) {
  return <p>{children}</p>;
}

Message.propTypes = {
  children: PropTypes.string.isRequired
};
