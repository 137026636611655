import React from 'react';
import { TwoColumns } from 'components';
import Text from './Text/Text';
import Image from './Image/Image';

export default function Special() {
  return (
    <>
      <TwoColumns
        heading="Join our Discord Server!"
        left={<Image />}
        right={<Text />}
      />
    </>
  );
}
