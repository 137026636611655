import React, {
  Component,
  useState,
  useCallback,
  useRef,
  useEffect
} from 'react';
import {
  ErrorMessage,
  SubmitButton,
  SecondaryButton,
  TextField
} from 'components/Form';
import ReactQuill, { Quill } from 'react-quill';
import * as Emoji from 'quill-emoji';
import ImageResize from 'quill-image-resize-module-react';
import parse from 'html-react-parser';
import {
  FaPlus,
  FaChevronLeft,
  FaChevronRight,
  FaChevronUp,
  FaChevronDown
} from 'react-icons/fa';
import PropTypes from 'prop-types';
import Joi from 'joi-browser';
import { connect } from 'react-redux';

import 'react-quill/dist/quill.snow.css';
import 'quill-emoji/dist/quill-emoji.css';
import './PostEditor.css';
import './TextField.css';
import createPost from 'services/forum/createPost';

const Block = Quill.import('blots/block');
Block.tagName = 'p';
Quill.register(Block);
Quill.register('modules/imageResize', ImageResize);
Quill.register('modules/emoji', Emoji);

const TOOLBAR_OPTIONS = [
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  [{ font: [] }],
  [{ list: 'ordered' }, { list: 'bullet' }],
  ['bold', 'italic', 'underline'],
  [{ color: [] }, { background: [] }],
  [{ script: 'sub' }, { script: 'super' }],
  [{ align: [] }],
  ['image', 'video', 'link', 'blockquote', 'code-block', 'emoji'],
  ['clean']
];

/**
 * React Hook for listening to vertical drag changes
 */
const useDragExpander = ({ min, max }) => {
  const [dragState, setDragState] = useState(0);
  const initialPos = useRef(0);
  const timer = useRef();

  const update = useCallback(
    (yPos) =>
      setDragState((state) => ({
        ...state,
        delta: initialPos.current - yPos + state.lastDelta
      })),
    []
  );
  const onDragMove = useCallback(
    (e) => update(e.screenY >= 0 ? e.screenY : 0),
    []
  );
  const dragStart = (e, boxHeight) => {
    setDragState((state) => ({
      ...state,
      lastDelta: boxHeight || 0, // state.delta
      isDragging: true
    }));
    window.addEventListener('mousemove', onDragMove);
  };

  const unbind = () => {
    clearTimeout(timer.current);
    window.removeEventListener('mousemove', onDragMove);
    setDragState((state) => ({ ...state, isDragging: false }));
  };

  const onDragMouseDown = (boxHeight, e) => {
    if (e.button !== 0) return; // only allow left-mouse clicks
    e.preventDefault();
    initialPos.current = e.screenY; // re-set initial position
    timer.current = setTimeout(dragStart(e, boxHeight), 0, e); // only allow dragging after N duration mouse down
    window.addEventListener('mouseup', unbind);
  };

  const limitDragRange = useCallback(
    (delta) => Math.min(max, Math.max(min, delta || 0)),
    []
  );

  return {
    onDragMouseDown,
    onDragMove,
    dragState,
    setDragState,
    limitDragRange
  };
};

/**
 * Border adjustment bar component
 */
function Gripple({ boxHeightCallback, currentBoxHeight }) {
  const { onDragMouseDown, dragState, limitDragRange } = useDragExpander({
    min: 100,
    max: window.innerHeight
  });
  const dragHeight = limitDragRange(dragState.delta);
  useEffect(() => {
    boxHeightCallback(dragHeight); // update grippler box height
    const root = document.documentElement; // update ql-editor height css variable
    /*
     * taking effect by updating the height of <div className= "quill__wrapper">
     */
    root?.style.setProperty('--form-area-height', `${dragHeight - 3}px`);
    //  verify the change
    root?.style.getPropertyValue('--form-area-height');
  }, [dragHeight]);

  return (
    <div
      className="gripple"
      onMouseDown={onDragMouseDown.bind(this, currentBoxHeight)}
    >
      {/* bar ripple texture */}
      <div />
      <div />
    </div>
  );
}

/**
 * Gripple prop-types
 */
Gripple.propTypes = {
  boxHeightCallback: PropTypes.func.isRequired,
  currentBoxHeight: PropTypes.number.isRequired
};

class PostEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // box appearance
      boxHeight: 500,
      previewOpened: false,
      boxMinimised: false,
      // error handling
      isLoading: false,
      formError: '',
      // submissions
      title: '',
      body: ''
    };
  }

  /**
   * Box controller callbacks
   */
  getBoxHeight = (newBoxHeight) => {
    this.setState({ boxHeight: newBoxHeight });
  };

  togglePreview = (e) => {
    e.preventDefault();
    const { previewOpened } = this.state;
    this.setState({ previewOpened: !previewOpened });
  };

  toggleBoxMinimiser = (e) => {
    e.preventDefault();
    const { boxMinimised } = this.state;
    this.setState({ boxMinimised: !boxMinimised });
  };

  render() {
    const {
      boxMinimised,
      boxHeight,
      previewOpened,
      isLoading,
      formError,
      title,
      body
    } = this.state;
    const { userId, channelName } = this.props;

    const controlledInputs = [
      {
        label: 'Title:',
        id: 'title',
        placeholder: 'Your Post Title',
        state: title,
        setState: (value) => this.setState({ title: value }),
        schema: Joi.string().min(2).max(50)
      }
    ];

    // Handle Form Submission
    const handleSubmitFormSubmission = (e) => {
      e.preventDefault();
      this.setState({ isLoading: true });
      this.setState({ formError: '' });

      createPost({
        postData: {
          title,
          body,
          userId,
          channelName
        }
      })
        .then(({ result, message }) => {
          if (result) {
            this.setState({ isLoading: false });
            this.setState({ formError: message });
          } else {
            this.setState({ isLoading: false });
            this.setState({ formError: message });
          }
        })
        .catch(() => {
          this.setState({ isLoading: false });
          this.setState({ formError: 'Sorry, an unknown error has occurred' });
        });
    };

    return (
      <form
        className={!boxMinimised ? 'post-editor' : 'post-editor--hidden'}
        onSubmit={handleSubmitFormSubmission}
      >
        <Gripple
          boxHeightCallback={this.getBoxHeight}
          currentBoxHeight={boxHeight}
        />
        {/* Box Minimise Button */}
        <button
          type="button"
          onClick={this.toggleBoxMinimiser}
          className={boxMinimised ? 'btn boxResumer' : 'btn boxResumer--hidden'}
        >
          {/* btn btn-secondary */}
          {boxMinimised ? <FaChevronUp /> : <FaChevronDown />}
        </button>
        <div className={!boxMinimised ? 'box-area' : 'box-area--hidden'}>
          <div className="form-area">
            {controlledInputs && (
              <TextField targetInput={controlledInputs[0]} />
            )}
            {/* post-editer__content */}
            <section className="quill__wrapper">
              <ReactQuill
                className="quill-editor"
                value={body}
                onChange={(val) => this.setState({ body: val })}
                modules={{
                  imageResize: {
                    modules: ['Resize', 'DisplaySize']
                  },
                  toolbar: {
                    container: TOOLBAR_OPTIONS
                  },
                  'emoji-toolbar': true,
                  'emoji-textarea': false,
                  'emoji-shortname': true
                }}
                placeholder="Compose an epic..."
                bounds="#parent"
                theme="snow"
              />
            </section>
            {formError && <ErrorMessage>{formError}</ErrorMessage>}

            {/* Control Ares */}
            <div className="control-area">
              <SubmitButton disabled={isLoading}>
                {isLoading ? (
                  'Loading...'
                ) : (
                  <>
                    {' '}
                    <FaPlus />
                    <span className="button-label">Create Topic</span>
                  </>
                )}
              </SubmitButton>
              {/* post-editer__controll */}
              <SecondaryButton
                disabled={isLoading}
                onClick={() => {
                  this.setState({ title: '', body: '' });
                }}
              >
                Reset All
              </SecondaryButton>

              {/* Box Minimise Button */}
              <button
                type="button"
                onClick={this.toggleBoxMinimiser}
                className="btn boxMinimiser"
              >
                {/* btn btn-secondary */}
                {boxMinimised ? <FaChevronUp /> : <FaChevronDown />}
              </button>

              {/* Preview Toogle Button */}
              <button
                type="button"
                onClick={this.togglePreview}
                className="btn togglePreview"
              >
                {/* btn btn-secondary */}
                {previewOpened ? <FaChevronRight /> : <FaChevronLeft />}
              </button>
            </div>
          </div>
          <div
            className={!previewOpened ? 'preview-area--hidden' : 'preview-area'}
          >
            <div className="preview-box">{parse(body)}</div>
          </div>
        </div>
      </form>
      // </ResizePanel>
    );
  }
}

const mapStateToProps = (state) => {
  const { user, channel } = state;
  return {
    userId: user?.user?._id,
    channelName: channel?.channel?.channel
  };
};

export default connect(mapStateToProps)(PostEditor);

PostEditor.propTypes = {
  userId: PropTypes.string.isRequired,
  channelName: PropTypes.string.isRequired
};
