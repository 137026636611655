import React from 'react';
import ReactDOM from 'react-dom';

import ReduxProvider from './store/ReduxProvider';
import App from './App/App';
import reportWebVitals from './reportWebVitals';
import './index.css';

ReactDOM.render(
  <ReduxProvider>
    <App />
  </ReduxProvider>,
  document.getElementById('root')
);

reportWebVitals();
