/* eslint-disable no-console */
import Axios from 'axios';
import { UPDATE_USER_SUCCESS, UPDATE_USER_FAIL } from 'store/actionTypes';

const updateSucceeded = (user) => ({
  type: UPDATE_USER_SUCCESS,
  user
});

const updateFailed = (user) => ({
  type: UPDATE_USER_FAIL,
  user
});

const updateUser = (newUser) => {
  const callback = (dispatch) => {
    // eslint-disable-next-line no-underscore-dangle
    const UPDATE_USER_API_URI = `${process.env.REACT_APP_API_URL}/user/${newUser._id}`;

    const token = sessionStorage.getItem('token');
    Axios.put(UPDATE_USER_API_URI, newUser, {
      headers: { Authorization: `Bearer ${token}` }
    })
      .then((response) => {
        dispatch(updateSucceeded(response.data));
      })
      .catch((error) => {
        dispatch(updateFailed(error));
      });
  };
  return callback;
};

export default updateUser;
