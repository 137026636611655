import {
  CHANNEL_FETCH_START,
  CHANNEL_FETCH_SUCCESS,
  CHANNEL_FETCH_FAIL
} from 'store/actionTypes';

const initialState = {
  isLoading: false,
  channel: [],
  error: ''
};

export default function channel(state = initialState, action) {
  switch (action.type) {
    case CHANNEL_FETCH_START:
      return {
        ...initialState,
        isLoading: true
      };

    case CHANNEL_FETCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        channel: action.channel
      };

    case CHANNEL_FETCH_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error
      };

    default:
      return state;
  }
}
