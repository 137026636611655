import React from 'react';
import PropTypes from 'prop-types';
import './SaveButton.css';

export default function SaveButton({ disabled }) {
  return (
    <button type="submit" className="save_password" disabled={disabled}>
      Save New Password
    </button>
  );
}

SaveButton.propTypes = {
  disabled: PropTypes.bool.isRequired
};
