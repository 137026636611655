import React from 'react';
import PropTypes from 'prop-types';

import './TermsContent.css';

export default function TermsContent({ term }) {
  const { title, subtitle, rules } = term;

  return (
    <section className="terms_content">
      <h2 className="terms_content--title">
        {title}
        <span className="terms_content--warning">{subtitle}</span>
      </h2>
      <ol className="terms_content--content">
        {rules.map((rule, index) => (
          <li key={index} className="terms_content--content--terms">
            {rule}
          </li>
        ))}
      </ol>
    </section>
  );
}

TermsContent.propTypes = {
  term: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    rules: PropTypes.arrayOf(PropTypes.string).isRequired
  }).isRequired
};
