import React from 'react';
import { Title } from 'components';
import './PhotoAlbum.css';

export default function PhotoAlbum() {
  return (
    <section className="about_us--photo_album">
      <section className="about_us--photo_album--wrapper">
        <Title fontColor="#fff">Photo Album</Title>

        <section className="about_us--photo_album--wrapper--body">
          <div className="about_us--photo_album--wrapper--body--card">
            <img
              src="https://github.com/TobbyToe/photos2021/blob/gh-pages/Screeenshot_55.png?raw=true"
              alt=""
            />
            <span>Photo A</span>
          </div>
          <div className="about_us--photo_album--wrapper--body--card">
            <img
              src="https://github.com/TobbyToe/photos2021/blob/gh-pages/Screenshot_16.png?raw=true"
              alt=""
            />
            <span>Photo B</span>
          </div>
          <div className="about_us--photo_album--wrapper--body--card">
            <img
              src="https://github.com/TobbyToe/photos2021/blob/gh-pages/Screenshot_58.png?raw=true"
              alt=""
            />
            <span>Photo C</span>
          </div>
        </section>
      </section>
    </section>
  );
}
