import React from 'react';

export default function Text() {
  return (
    <p>
      Lorem ipsum dolor sit amet consectetur, adipisicing elit. Illum,
      aspernatur. Atque ullam iste ex doloremque fugiat sequi sed corporis
      repellat saepe quasi iure amet, dignissimos qui! Dolor unde labore
      quibusdam? Lorem ipsum dolor sit amet consectetur, adipisicing elit.
      Illum, aspernatur. Atque ullam iste ex doloremque fugiat sequi sed
      corporis repellat saepe quasi iure amet, dignissimos qui! Dolor unde
      labore quibusdam?
    </p>
  );
}
