import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import './Menu.css';

export default function Menu({ menuOptions }) {
  return (
    <div className="menu">
      <ul>
        {menuOptions &&
          menuOptions.map(({ linkTo, label, exact }, index) => (
            <li key={index}>
              <NavLink to={linkTo} exact={exact}>
                {label}
              </NavLink>
            </li>
          ))}
      </ul>
    </div>
  );
}

Menu.propTypes = {
  menuOptions: PropTypes.arrayOf(
    PropTypes.shape({
      linkTo: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      exact: PropTypes.bool
    })
  ).isRequired
};
