import Axios from 'axios';
import {
  CHANNEL_FETCH_START,
  CHANNEL_FETCH_SUCCESS,
  CHANNEL_FETCH_FAIL
} from 'store/actionTypes';

const URI = `${process.env.REACT_APP_API_URL}/channel`;

const fetchStarted = () => ({
  type: CHANNEL_FETCH_START
});

const fetchSucceed = (res) => ({
  type: CHANNEL_FETCH_SUCCESS,
  channel: res
});

const fetchFailed = (error) => ({
  type: CHANNEL_FETCH_FAIL,
  error
});

export default function getChannelData(id) {
  const callback = (dispatch) => {
    dispatch(fetchStarted());

    Axios.get(`${URI}/${id}`)
      .then((res) => {
        dispatch(fetchSucceed(res.data));
      })
      .catch((error) => {
        if (typeof error.response !== 'undefined') {
          if (typeof error.response.data !== 'undefined')
            dispatch(fetchFailed(error.response.data));
          else
            dispatch(
              fetchFailed(
                'An unexpected error has occurred, please try again later.'
              )
            );
        } else
          dispatch(
            fetchFailed('Unable to reach server, please try again later.')
          );
      });
  };
  return callback;
}
