import { React, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { List, Comment, Avatar } from 'antd';
import Axios from 'axios';
import { useSelector } from 'react-redux';
// import parse from 'html-react-parser';
import formatDate from 'utils/formateDate';
import { MainContent, Editor } from './components/index';
import './PostDetailPage.css';
import 'antd/dist/antd.css';
// import Editor from './components/Comments/Editor';

export default function PostDetailPage(props) {
  const user = useSelector((state) => state.user);
  // eslint-disable-next-line react/destructuring-assignment
  const { postId } = props.match.params;
  // eslint-disable-next-line react/destructuring-assignment
  const [post, setPost] = useState([]);
  const [CommentLists, setCommentLists] = useState([]);
  const [User, setUser] = useState([]);
  const pURI = `${process.env.REACT_APP_API_URL}/post/`;
   const cURI = `${process.env.REACT_APP_API_URL}/comment/`;

  useEffect(() => {
    Axios.get(`${pURI}/${postId}`).then((response) => {
      if (response.data) {
        setPost(response.data);
        setUser(response.data.author);
      } else {
        // eslint-disable-next-line no-alert
        alert('Failed to get post Info');
      }
    });
    Axios.get(`${cURI}/${postId}`).then((response) => {
      if (response.data) {
        setCommentLists(response.data);
      } else {
        // eslint-disable-next-line no-alert
        alert('Failed to get comment Info');
      }
    });
  }, []);
  const updateComment = (newComment) => {
    setCommentLists(CommentLists.concat(newComment));
  };
  
  return (
    <div className="container">
      <div className="__content-container">
        <List.Item.Meta
          avatar={<Avatar src={User.avatar} />}
          title={<a href={post.href}>{post.title}</a>}
          description={post.description}
        />
        <MainContent body={post.body} />
        Created At :{formatDate(post.createdAt)} by {User.username}
      </div>
      <div className="comment-container">
        <List
          className="comment-list"
          // header={CommentLists.username}
          itemLayout="horizontal"
          dataSource={CommentLists}
          renderItem={(item) => (
            <li>
              <>
                <Comment
                  avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
                  actions={item.actions}
                  author={item.username}
                  content={item.content}
                  datetime={formatDate(item.createdAt)}
                />
              </>
            </li>
          )}
        />
        <Editor
          postId={postId}
          username={user.username}
          refreshFunction={updateComment}
        />
      </div>
      <Comment />
    </div>
  );
}
PostDetailPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      postId: PropTypes.string.isRequired,
      _id: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};
