import Axios from 'axios';
import {
  USER_LOGIN_START,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL
} from 'store/actionTypes';

const URI = `${process.env.REACT_APP_API_URL}/login`;

// Login User
const loginStarted = () => ({
  type: USER_LOGIN_START
});

const loginSucceed = (res) => ({
  type: USER_LOGIN_SUCCESS,
  user: res
});

const loginFailed = (error) => ({
  type: USER_LOGIN_FAIL,
  error
});

export default function loginUser({ email, password }) {
  const callback = (dispatch) => {
    dispatch(loginStarted());

    Axios.post(URI, {
      email,
      password
    })
      .then((response) => {
        sessionStorage.setItem('token', response.data.token);
        dispatch(loginSucceed(response.data.user));
      })
      .catch((error) => {
        if (typeof error.response !== 'undefined') {
          if (typeof error.response.data !== 'undefined')
            dispatch(loginFailed(error.response.data));
          else
            dispatch(
              loginFailed(
                'An unexpected error has occurred, please try again later.'
              )
            );
        } else
          dispatch(
            loginFailed('Unable to reach server, please try again later.')
          );
      });
  };

  return callback;
}
