import React from 'react';
import PropTypes from 'prop-types';
import './SaveButton.css';

export default function SaveButton({ onClick }) {
  return (
    <button className="save_changes" type="submit" onClick={onClick}>
      Save Changes
    </button>
  );
}

SaveButton.propTypes = {
  onClick: PropTypes.string.isRequired
};
