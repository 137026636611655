import React from 'react';
import { motion } from 'framer-motion';
import logo from 'assets/logo.svg';
import './Logo.css';

export default function Logo() {
  return (
    <div className="intro-logo">
      <motion.img
        initial={{ rotate: 0 }}
        animate={{ rotate: 360 }}
        transition={{ ease: 'linear', repeat: Infinity, duration: 20 }}
        src={logo}
        alt="logo"
      />
    </div>
  );
}
