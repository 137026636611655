import React from 'react';
import { TwoColumns } from 'components/index';
import Text from './Text/Text';
import Logo from './Logo/Logo';

export default function Introduction() {
  return (
    <TwoColumns
      heading="What is Great Phermesia?"
      left={<Text />}
      right={<Logo />}
    />
  );
}
