import Axios from 'axios';

const URI = `${process.env.REACT_APP_API_URL}/post`;

export default async function createPost({ postData }) {
  const { title, body, userId, channelName } = postData;

  const callback = Axios.post(URI, {
    title,
    body,
    author: userId,
    channel: channelName
  }).then((response) => {
    if (response.status === 201)
      return { result: true, message: 'Post created successfully' };
    return { result: false, message: 'Something went wrong' };
  });

  return callback;
}
