import Axios from 'axios';
import Joi from 'joi-browser';

const URI = `${process.env.REACT_APP_API_URL}/register`;

export default async function registerUser({ formData }) {
  const { controlledInputs, confirmRule } = formData;

  if (!confirmRule)
    return {
      result: false,
      error: 'You need to agree to our rules before registering!'
    };

  const validateFields = () => {
    let isValid = true;
    controlledInputs.forEach((input) => {
      const { state, schema } = input;
      const result = Joi.validate(state, schema);
      if (result.error) isValid = false;
    });
    return isValid;
  };

  if (!validateFields()) {
    return {
      result: false,
      error: 'All fields must be valid!'
    };
  }

  const [username, email, password] = controlledInputs;
  const callback = Axios.post(URI, {
    username: username.state,
    email: email.state,
    password: password.state,
    confirmRule
  })
    .then((response) => {
      if (response.status === 200) return { result: true };
      return { result: false, error: response.data };
    })
    .catch((error) => ({ result: false, error: error.response.data }));

  return callback;
}
