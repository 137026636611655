import React, { useState } from 'react';
import axios from 'axios';
import { ErrorMessage, TextField, SubmitButton } from 'components/Form';
import './ForgotPasswordPage.css';
import Joi from 'joi-browser';

export default function ForgotPasswordPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [email, setEmail] = useState('');
  const [formError, setFormError] = useState('');

  const controlledInputs = [
    {
      label: 'Email:',
      id: 'email',
      placeholder: 'Your Email Address',
      state: email,
      setState: setEmail,
      schema: Joi.string().email()
    }
  ];

  const FP_API_URL = `${process.env.REACT_APP_API_URL}/passwordReset`;
  const handleForgetPasswordFormSubmission = (event) => {
    event.preventDefault();
    setIsLoading(true);
    setFormError('');

    axios
      .put(FP_API_URL, { email })
      .then((response) => {
        setIsLoading(false);
        response.status === 200
          ? setSuccess(true)
          : setFormError(response.body);
      })
      .catch((error) => {
        setIsLoading(false);
        setFormError(error.response.data);
      });
  };

  return success ? (
    <section className="forgot_password">
      <h2 className="forgot_password--title">Recover Password</h2>
      <p>The email with instructions was sent to your email account.</p>
    </section>
  ) : (
    <section className="forgot_password">
      <h2 className="forgot_password--title">Forgot Password</h2>
      <p className="forgot_password--content">
        Please enter your email address below, and we will send you a link to a
        page that allows you to reset your password.
      </p>

      <form
        className="forgot_password--form"
        onSubmit={handleForgetPasswordFormSubmission}
      >
        {formError && <ErrorMessage>{formError}</ErrorMessage>}
        {controlledInputs &&
          controlledInputs.map((input) => <TextField targetInput={input} />)}
        <SubmitButton disabled={!email}>
          {isLoading ? 'Sending...' : 'Please Check your email'}
        </SubmitButton>
      </form>
    </section>
  );
}
