import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import {
  HomePage,
  ForumPage,
  ChannelPage,
  AboutUsPage,
  LoginPage,
  LoggedInPage,
  SignUpPage,
  RegisteredPage,
  TermsAndConditionsPage,
  ForgotPasswordPage,
  ResetPasswordPage,
  ProfilePage,
  ProfileEditPage,
  PostDetailPage
} from 'pages';

import { Header, Footer } from './components';
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Switch>
          <Route path="/" component={HomePage} exact />
          <Route exact path="/forum" component={ForumPage} />
          <Route
            exact
            path="/forum/:categoryId/:channelId"
            component={ChannelPage}
          />
          <Route
            exact
            path="/forum/:categoryId/:channelId/:postId"
            component={PostDetailPage}
          />


          <Route path="/about" component={AboutUsPage} />
          <Route path="/login" component={LoginPage} />
          <Route path="/logged-in" component={LoggedInPage} />
          <Route path="/sign-up" component={SignUpPage} />
          <Route path="/registered" component={RegisteredPage} />
          <Route path="/forgot-password" component={ForgotPasswordPage} />
          <Route path="/reset-password" component={ResetPasswordPage} />
          <Route
            path="/terms-and-conditions"
            component={TermsAndConditionsPage}
          />
          <Route path="/profile" component={ProfilePage} />
          <Route path="/edit-profile" component={ProfileEditPage} />
        </Switch>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
