import React, { useState, useMemo } from 'react';
import { Link, NavLink, useHistory } from 'react-router-dom';
import _ from 'lodash';
import Joi from 'joi-browser';

import { ErrorMessage, TextField, SubmitButton } from 'components/Form';
import registerUser from 'services/auth/registerUser';
import './SignUpPage.css';

export default function SignUpPage() {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [formError, setFormError] = useState('');

  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmRule, setConfirmRule] = useState(false);

  const controlledInputs = [
    {
      label: 'Username:',
      id: 'username',
      placeholder: 'Your username',
      state: username,
      setState: setUsername,
      schema: Joi.string().min(5).max(16)
    },
    {
      label: 'Email:',
      id: 'email',
      placeholder: 'Your Email Address',
      state: email,
      setState: setEmail,
      schema: Joi.string().email()
    },
    {
      label: 'Password:',
      type: 'password',
      id: 'password',
      placeholder: 'Your Password',
      state: password,
      setState: setPassword,
      schema: Joi.string()
        .regex(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/)
        .error(
          new Error(
            'Passwords must have at least 8 characters and contain at least each of following: uppercase letters, lowercase letters and numbers.'
          )
        )
    },
    {
      label: 'Confirm Password:',
      type: 'password',
      id: 'confirmPassword',
      placeholder: 'Confirm Your Password',
      state: confirmPassword,
      setState: setConfirmPassword,
      schema: Joi.string()
        .valid(password)
        .error(new Error('Password does not match!'))
    }
  ];

  // Manage form submit button status
  const isReady = useMemo(() => {
    const resultArr = controlledInputs.filter((input) =>
      _.isEmpty(input.state) ? input : null
    );
    return resultArr.length === 0;
  }, [controlledInputs]);

  // Handle Form Submission
  const handleSignUpFormSubmission = (event) => {
    event.preventDefault();
    setIsLoading(true);
    setFormError('');

    registerUser({
      formData: {
        controlledInputs,
        confirmRule
      }
    })
      .then(({ result, error }) => {
        if (result) history.replace('/registered');
        else {
          setIsLoading(false);
          setFormError(error);
        }
      })
      .catch(() => {
        setIsLoading(false);
        setFormError('Sorry, an unknown error has occured');
      });
  };

  return (
    <section className="signup">
      <h2 className="signup--title">Sign Up</h2>

      <form className="signup--form" onSubmit={handleSignUpFormSubmission}>
        {formError && <ErrorMessage>{formError}</ErrorMessage>}
        {controlledInputs &&
          controlledInputs.map((input) => <TextField targetInput={input} />)}

        <label className="form--checkbox" htmlFor="confirmRules">
          <input
            type="checkbox"
            id="confirmRules"
            onChange={(e) => {
              if (e.target.checked) setConfirmRule(true);
              else setConfirmRule(false);
            }}
          />
          <p>
            {'By checking this box, you are agreeing to our '}
            <Link to="/terms-and-conditions">Terms and Conditions</Link>
          </p>
        </label>
        {/* ReCAPTCHA */}
        <SubmitButton disabled={!isReady}>
          {isLoading ? 'Loading...' : 'Sign Up'}
        </SubmitButton>
      </form>

      <div className="signup--options">
        <p>
          {'Already has an account? '}
          <NavLink to="/login">Login here!</NavLink>
        </p>
      </div>
    </section>
  );
}
