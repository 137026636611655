import React from 'react';

import { Menu } from 'components';
import discord from './assets/discord.svg';
import instagram from './assets/instagram.svg';
import './Footer.css';

const socialMedia = [
  {
    icon: instagram,
    name: 'instagram',
    link: 'https://www.instagram.com/greatphermesia/',
    id: 1
  },
  {
    icon: discord,
    name: 'discord',
    link: 'https://discord.gg/xk6rCZFwkj',
    id: 2
  }
];

const menuOptions = [
  {
    linkTo: '/terms-and-conditions',
    label: 'Terms & Conditions'
  }
];

const Footer = () => (
  <footer>
    <div className="wrapper">
      <p>Copyright © Great Phermesia {new Date().getFullYear()}</p>

      <nav>
        <Menu menuOptions={menuOptions} />
      </nav>

      <ul className="medias">
        {socialMedia.map((item) => {
          const { icon, name, link, id } = item;
          return (
            <li key={id}>
              <a href={link} target="_blank" rel="noreferrer">
                <img src={icon} alt={name} />
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  </footer>
);

export default Footer;
