import React from 'react';
import PropTypes from 'prop-types';

import './SecondaryButton.css';

export default function SecondaryButton({ children, disabled, onClick }) {
  return (
    <button
      type="button"
      className="form--button-secondary"
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  );
}

SecondaryButton.propTypes = {
  children: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
};
