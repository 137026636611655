import React, { useState } from 'react';
import { Button, Input } from 'antd';
import Axios from 'axios';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

const URI = `${process.env.REACT_APP_API_URL}/comment`;
const { TextArea } = Input;
export default function Editor(props) {
  const { postId, refreshFunction } = props;
  const { user } = useSelector((state) => state.user);
  const [Comment, setComment] = useState('');

  const onSubmit = (e) => {
    e.preventDefault();
    const variables = {
      username: user.username,
      postId,
      avatar: user.avatar,
      content: Comment
    };

    if (Comment === undefined || Comment === '') return;
    Axios.post(URI, variables).then((response) => {
      if (response.data) {
        setComment('');
        refreshFunction(response.data);
      } else {
        // eslint-disable-next-line no-alert
        alert('Failed to save Comment');
      }
    });
  };
  const handleChange = (e) => {
    setComment(e.currentTarget.value);
  };
  return (
    <>
      <form onSubmit={onSubmit}>
        <TextArea
          avatar={user.avatar}
          style={{ width: '50%', borderRadius: '5px' }}
          onChange={handleChange}
          value={Comment}
          placeholder="write some comments"
        />
        <br />
        <Button
          type="primary"
          shape="round"
          style={{ width: '20%', height: '52px', marginTop: '20px' }}
          onClick={onSubmit}
        >
          Submit
        </Button>
      </form>
    </>
  );
}

Editor.propTypes = {
  postId: PropTypes.string.isRequired,
  refreshFunction: PropTypes.func.isRequired
};
