import {
  CATEGORY_FETCH_START,
  CATEGORY_FETCH_SUCCESS,
  CATEGORY_FETCH_FAIL
} from 'store/actionTypes';

const initialState = {
  isLoading: false,
  categories: [],
  error: ''
};

export default function forum(state = initialState, action) {
  switch (action.type) {
    case CATEGORY_FETCH_START:
      return {
        ...initialState,
        isLoading: true
      };

    case CATEGORY_FETCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        categories: action.categories
      };

    case CATEGORY_FETCH_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error
      };

    default:
      return {
        ...state
      };
  }
}
