import React from 'react';
import bg from 'assets/images/Screenshot_5.png';
import { PageBanner } from 'components';
import { Mission, PhotoAlbum } from './components';
import './AboutUsPage.css';

export default function AboutUsPage() {
  return (
    <section className="about_us">
      <PageBanner title="Get To Know Us" bg={bg} />
      <Mission />
      <PhotoAlbum />
    </section>
  );
}
