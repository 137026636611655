/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import Axios from 'axios';
import { useSelector } from 'react-redux';
import Joi from 'joi-browser';
import { Title } from 'components';
import { ErrorMessage } from 'components/Form';
import { TextField, SaveButton } from './components';
import './PasswordChange.css';

export default function ResetPasswordPage() {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [formError, setFormError] = useState('');
  const [formSucceed, setFormSucceed] = useState('');
  const { user } = useSelector((state) => state.user);

  const controlledInputs = [
    {
      label: 'Current Password:',
      type: 'password',
      id: 'currentPassword',
      placeholder: 'Your Current Password',
      state: currentPassword,
      setState: setCurrentPassword,
      schema: Joi.string().error(
        new Error('Please enter your current password.')
      )
    },
    {
      label: 'New Password:',
      type: 'password',
      id: 'newPassword',
      placeholder: 'Your New Password',
      state: newPassword,
      setState: setNewPassword,
      schema: Joi.string()
        .regex(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/)
        .error(
          new Error(
            'The password must have at least one of Number, Lowercase Letter, Uppercase Letter, and minimum of 8 characters.'
          )
        )
    },
    {
      label: 'Confirm Your New Password:',
      type: 'password',
      id: 'confirmPassword',
      placeholder: 'Confirm Your New Password',
      state: confirmPassword,
      setState: setConfirmPassword,
      schema: Joi.string()
        .valid(newPassword)
        .error(new Error('Password not match'))
    }
  ];

  const onSubmit = (e) => {
    e.preventDefault();
    const URL = `${process.env.REACT_APP_API_URL}/passwordReset/update/${user._id}`;
    const token = sessionStorage.getItem('token');
    Axios.put(
      URL,
      {
        currentPassword,
        newPassword
      },
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    )
      .then((response) => {
        setFormSucceed('Password change successfully');
      })
      .catch((err) => {
        setFormError(err.response.data);
      });
  };

  return (
    <section className="password_change">
      <Title> Change Password </Title>
      <form onSubmit={onSubmit} className="password_change--pannel">
        {controlledInputs &&
          controlledInputs.map((input) => (
            <TextField targetInput={input} isInvalid={formError && true} />
          ))}
        {formError && <ErrorMessage>{formError}</ErrorMessage>}
        {formSucceed && <p>{formSucceed}</p>}
        <SaveButton
          disabled={
            !currentPassword ||
            !newPassword ||
            !confirmPassword ||
            !newPassword.match(
              /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/
            ) ||
            newPassword !== confirmPassword
          }
        />
      </form>
    </section>
  );
}
