import React from 'react';
import banner from './assets/banner.mp4';
import './Banner.css';

const Banner = () => (
  <section className="banner">
    <video autoPlay muted loop className="video">
      <source src={banner} type="video/mp4" />
      Your browser does not support HTML5 video.
    </video>
    <div className="welcome">
      <h1>Welcome to Great Phermesia</h1>
    </div>
  </section>
);

export default Banner;
