import React from 'react';
import PropTypes from 'prop-types';
import { Title } from 'components/index';
import './TwoColumns.css';

export default function TwoColumns({ heading, left, right, bgColor }) {
  return (
    <section className="two-col" style={{ backgroundColor: bgColor }}>
      <div className="two-col__wrapper">
        <Title>{heading}</Title>
        <div className="two-col__wrapper__body">
          <div className="two-col__wrapper__body__left">{left}</div>
          <div className="two-col__wrapper__body__right">{right}</div>
        </div>
      </div>
    </section>
  );
}

TwoColumns.propTypes = {
  heading: PropTypes.string.isRequired,
  left: PropTypes.string.isRequired,
  right: PropTypes.string.isRequired,
  bgColor: PropTypes.string.isRequired
};
