import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import './LoggedInPage.css';

export default function LoggedInPage() {
  const { user } = useSelector((state) => state.user);
  const [username, setUsername] = useState('');

  useEffect(() => {
    setUsername(user.username);
  }, [user]);

  return (
    <section className="user_auth--logged_in">
      <h2>Welcome back! {username}</h2>
      <p>
        Click this link here to go back to <Link to="/">home page!</Link>
      </p>
    </section>
  );
}
