import React, { useState } from 'react';
import axios from 'axios';

import { ErrorMessage, SubmitButton, TextField } from 'components/Form';
import './ResetPasswordPage.css';

import { NavLink } from 'react-router-dom';

import Joi from 'joi-browser';
import ResetPasswordFail from './ResetPasswordFail';
import ResetPasswordSuccess from './ResetPasswordSuccess';

export default function ResetPasswordPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [formError, setFormError] = useState('');
  const [success, setSuccess] = useState(false);

  const controlledInputs = [
    {
      label: 'Password:',
      type: 'password',
      id: 'password',
      placeholder: 'Your Password',
      state: password,
      setState: setPassword,
      schema: Joi.string()
        .regex(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/)
        .error(
          new Error(
            'The password must have at least one of Number, Lowercase Letter, Uppercase Letter, and minimum of 8 characters.'
          )
        )
    },
    {
      label: 'ConfirmPassword:',
      type: 'password',
      id: 'confirmPassword',
      placeholder: 'Confirm Your Password',
      state: confirmPassword,
      setState: setConfirmPassword,
      schema: Joi.string()
        .valid(password)
        .error(new Error('Password not match'))
    }
  ];

  //   get token
  const getUrlToken = window.location.search.substr(1);

  // submit method
  const onResetClicked = (e) => {
    e.preventDefault();
    axios
      .put(`${process.env.REACT_APP_API_URL}/passwordReset/${getUrlToken}`, {
        password
      })
      .then((response) => {
        setIsLoading(false);
        response.status === 200
          ? setSuccess(true)
          : setFormError(response.body);
      })
      .catch((err) => {
        setIsLoading(false);
        setFormError(err.response.data);
      });
  };

  if (success) return <ResetPasswordSuccess />;
  if (formError) return <ResetPasswordFail />;

  return (
    <section className="reset_password_page">
      <h2 className="reset_password_page--title">Reset Your Password</h2>

      <form className="reset_password_page--form" onSubmit={onResetClicked}>
        {formError && <ErrorMessage>{formError}</ErrorMessage>}
        {controlledInputs &&
          controlledInputs.map((input) => (
            <TextField targetInput={input} isInvalid={formError && true} />
          ))}

        <SubmitButton
          disabled={
            !password.match(
              /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/
            ) ||
            !password ||
            !confirmPassword ||
            password !== confirmPassword
          }
        >
          {isLoading ? 'Sending...' : 'Reset Password'}
        </SubmitButton>
      </form>
      <div className="ResetPasswordPage--options">
        <p>
          {"Don't have an account yet? "}
          <NavLink to="/sign-up">Sign up!</NavLink>
        </p>
      </div>
    </section>
  );
}
