import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

function IsAuthorised({ children, accessLevel }) {
  const { user } = useSelector((state) => state.user);

  const isAuthorised = useMemo(() => user.role === accessLevel, [user]);

  return isAuthorised ? children : <></>;
}

IsAuthorised.propTypes = {
  children: PropTypes.element.isRequired,
  accessLevel: PropTypes.number.isRequired
};

export const UserLevel = ({ children }) => (
  <IsAuthorised accessLevel={1}>{children}</IsAuthorised>
);
UserLevel.propTypes = {
  children: PropTypes.element.isRequired
};

export const ModeratorLevel = ({ children }) => (
  <IsAuthorised accessLevel={2}>{children}</IsAuthorised>
);
ModeratorLevel.propTypes = { children: PropTypes.element.isRequired };

export const AdminLevel = ({ children }) => (
  <IsAuthorised accessLevel={3}>{children}</IsAuthorised>
);
AdminLevel.propTypes = { children: PropTypes.element.isRequired };
