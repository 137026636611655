/* eslint-disable no-console */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line import/no-unresolved
import updateUser from 'store/actions/updateUser.action';
import formatDate from 'utils/formateDate';
import { ErrorMessage } from 'components/Form';
import { Title } from 'components';
import { UpdateAvatar, Input, SaveButton } from './components';

import './ProfileEdit.css';

export default function ProfileEdit() {
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const fields = [
    {
      key: 'username',
      id: 'username',
      label: 'Username',
      info: user.username,
      validate: (username) => {
        if (username.length < 5 || username.length > 16 || !username) {
          return 'Please input at least five characters';
        }
        return '';
      }
    },
    {
      key: 'discordTag',
      id: 'discord-tag',
      label: 'Discord Tag',
      info: user.discordTag,
      validate: (discordTag) => {
        if (!discordTag) {
          return '';
        }
        return '';
      }
    },
    {
      key: 'gameId',
      id: 'game-id',
      label: 'Game ID',
      info: user.gameId,
      validate: (gameId) => {
        if (!gameId) {
          return '';
        }
        return '';
      }
    },
    {
      key: 'descriptions',
      id: 'descriptios',
      label: 'Description',
      info: user.descriptions,
      validate: (descriptions) => {
        if (!descriptions) {
          return '';
        }
        return '';
      }
    }
  ];

  const [allData, setAllData] = useState({
    username: user.username,
    discordTag: user.discorTag,
    gameId: user.gameId,
    descriptions: user.description
  });

  const [touched, setTouched] = useState({
    username: false,
    discordTag: false,
    gameId: false,
    descriptions: false
  });

  const onDataChange = (key, value) => {
    setAllData((prevData) => ({ ...prevData, [key]: value }));
    setTouched((prevTouched) => ({ ...prevTouched, [key]: true }));
  };
  const { username, discordTag, gameId, descriptions } = allData;

  const isValid = fields.every(
    (item) => item.validate(allData[item.key]) === ''
  );

  const [errorHint, setErrorHint] = useState(false);
  const [successHint, setSuccessHint] = useState(false);

  const handleOnClick = () => {
    const newUser = {
      ...user,
      username,
      discordTag,
      gameId,
      descriptions
    };

    if (isValid) {
      setSuccessHint(true);
      setErrorHint(false);
      dispatch(updateUser(newUser));
    } else {
      setErrorHint(true);
    }
  };

  return (
    <>
      <section className="profile_edit">
        <Title> Edit Profile </Title>
        <div className="profile_edit--pannel">
          <div className="profile_edit--pannel--row">
            <UpdateAvatar />
            <div className="profile_edit--pannel--row--show">
              <h3 className="profile_edit--pannel--row--show--username">
                {user.username}
              </h3>
              <p className="profile_edit--pannel--row--show--email">
                {user.email}
              </p>
              <p className="profile_edit--pannel--row--show--created">
                Joined on {formatDate(user.createdAt)}
              </p>
            </div>
          </div>

          <form className="profile_edit--pannel--column" autoComplete="off">
            {fields.map(({ key, id, label, info, validate }) => {
              const errorMessage = validate(allData[key]);
              const error = touched[key] && errorMessage;

              return (
                <>
                  <Input
                    key={key}
                    id={id}
                    label={label}
                    info={info}
                    error={error}
                    onChange={(event) => onDataChange(key, event.target.value)}
                  />
                  {error && <ErrorMessage>{errorMessage}</ErrorMessage>}
                </>
              );
            })}
          </form>

          <div className="profile_edit--pannel--save">
            {errorHint && <ErrorMessage>Please check the fields</ErrorMessage>}
            {successHint && !errorHint && <p>Changes have been saved</p>}
            <SaveButton onClick={handleOnClick} />
          </div>
        </div>
      </section>
    </>
  );
}
