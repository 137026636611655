import React from 'react';

export default function Text() {
  return (
    <p>
      Great Phermesia is a Discord server welcoming players from around the
      world and from all walks of life. We strive to be an all-inclusive
      community made of people from many backgrounds. Our goal is to become a
      premier community while maintaining a safe, friendly environment where
      members and staff can form relationships and grow as players and people.
      To put it simply -- we want to be the best, and to be made up of the best.
    </p>
  );
}
