import React from 'react';
import './ResetPasswordPage.css';
import { useHistory } from 'react-router-dom';

export default function ResetPasswordSuccess() {
  const history = useHistory();
  return (
    <section className="reset_password_page">
      <h2 className="reset_password_page--title">Reset Password Success</h2>
      <p>
        Your password has been reset successfully, please login with your new
        password
      </p>
      <div className="reset_password_page--statement">
        <button
          type="submit"
          className="form--button-submit"
          onClick={() => history.push('./logged-in')}
        >
          Log in
        </button>
      </div>
    </section>
  );
}
