import React from 'react';
import { NavLink } from 'react-router-dom';

import './RegisteredPage.css';

export default function RegisteredPage() {
  return (
    <section className="registered">
      <h2 className="registered--title">Welcome Aboard!</h2>
      <p className="registered__message">
        Your registration is finished, click here to{' '}
        <NavLink to="/login">login</NavLink> 👈
      </p>
    </section>
  );
}
