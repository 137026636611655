import React from 'react';
import './Text.css';

export default function Text() {
  return (
    <div className="join-discord">
      <p>
        The Discord server is a place to hang out in the very heart of the Great
        Phermesia community. Engage in interesting discussions, make new friends
        from all over the world, and have a fun time!
      </p>
      <button type="button">
        <a
          href="https://discord.com/invite/xk6rCZFwkj"
          rel="noreferrer"
          target="_blank"
        >
          Join Us
        </a>
      </button>
    </div>
  );
}
