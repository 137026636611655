import React from 'react';

import { PageBanner } from 'components';
import TermContent from './TermsContent/TermsContent';
import terms from './asset/serverRules.json';

export default function TermsAndConditionsPage() {
  return (
    <section className="terms_page">
      <PageBanner title="Terms and Conditions" />
      <section className="terms_page--term">
        {terms.map((term, index) => (
          <TermContent key={index} term={term} />
        ))}
      </section>
    </section>
  );
}
