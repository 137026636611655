import React from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { FaThumbtack } from 'react-icons/fa';
import './PostItem.css';

export default function PostItem({ post }) {
  const { _id, pinned, title, author, commentCount, likeCount } = post;
  const history = useHistory();
  const { url } = useRouteMatch();

  const handleClick = () => {
    history.push(`${url}/${_id}`);
  };

  return (
    <tr className="post-item" onClick={handleClick}>
      <td>{pinned && <FaThumbtack />}</td>
      <td className="post-item__thread">
        <h2 className="post-item__text--level-large">{title}</h2>
        <p className="post-item__text--level-small">{author?.username}</p>
      </td>
      <td className="post-item__cell--meta">
        {commentCount}
        <p className="post-item__text--level-small">comments</p>
      </td>
      <td className="post-item__cell--meta">
        {likeCount}
        <p className="post-item__text--level-small">views</p>
      </td>
      <td>
        <p>last post</p>
        <p>last author</p>
        <p>time stamp</p>
      </td>
    </tr>
  );
}

PostItem.propTypes = {
  post: PropTypes.object.isRequired
};
