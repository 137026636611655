import React from 'react';
import { TwoColumns } from 'components';
import {
  SpecialPointLeft,
  SpecialPointRight
} from './SpecialPoint/SpecialPoint';

export default function Special() {
  return (
    <>
      <TwoColumns
        heading="What makes Great Phermesia Special?"
        left={<SpecialPointLeft />}
        right={<SpecialPointRight />}
        bgColor="#f7f7f7"
      />
    </>
  );
}
