import React from 'react';
import { NavLink } from 'react-router-dom';

import { SvgLogo } from 'assets/icons';
import './Brand.css';

export default function Brand() {
  return (
    <NavLink to="/" className="brand">
      <SvgLogo className="brand__logo" />
      <span>Great Phermesia</span>
    </NavLink>
  );
}
