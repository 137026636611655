export default function formatDate(now) {
  if (now === undefined) return null;

  const date = new Date(now);
  const day = date.getDay();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
}
