import React from 'react';
import { TwoColumns } from 'components';
import Text from './Text/Text';
import Image from './Image/Image';

export default function Mission() {
  return (
    <TwoColumns
      heading="Mission Statement"
      left={<Text />}
      right={<Image />}
      bgColor="#f7f7f7"
    />
  );
}
