import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import getChannelData from 'store/actions/forum/getChannelData.action';
import { UserLevel } from 'components/Auth';
import { PageBanner, ListTable } from 'components';
import { PostItem, PostEditor } from './components';
import './ChannelPage.css';

export default function ChannelPage() {
  const { categories } = useSelector((state) => state.category);
  const { isLoading, channel, error } = useSelector((state) => state.channel);
  const dispatch = useDispatch();
  const { categoryId, channelId } = useParams();
  const tableData = {
    colGroup: ['0', '', '0', '0', '0'],
    header: ['pinned', 'threads', 'comments', 'likes', 'last comment']
  };

  // This code smells 🤦‍♂️
  const id = useMemo(() => {
    const category = categories.find(({ _id }) => {
      const transformedId = _id.toLowerCase().replace(' ', '-');
      return categoryId === transformedId;
    });
    const result = category?.channels?.find((item) => {
      const transformedChannel = item.channel.toLowerCase().replace(' ', '-');
      return transformedChannel === channelId;
    });

    return result?._id;
  }, [categories]);

  useEffect(() => {
    dispatch(getChannelData(id));
  }, []);

  const posts = useMemo(() => channel?.posts, [channel]);

  return (
    <section className="channel">
      <PageBanner title="Channel One" />
      <section className="channel__content">
        <ListTable tableData={tableData} error={error} isLoading={isLoading}>
          {posts &&
            posts.map((post) => <PostItem key={post._id} post={post} />)}
        </ListTable>
      </section>
      <UserLevel>
        <PostEditor />
      </UserLevel>
    </section>
  );
}
