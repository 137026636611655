import React from 'react';
import PropTypes from 'prop-types';

import './SubmitButton.css';

export default function SubmitButton({ children, disabled }) {
  return (
    <button type="submit" className="form--button-submit" disabled={disabled}>
      {children}
    </button>
  );
}

SubmitButton.propTypes = {
  children: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired
};
