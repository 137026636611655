import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { ErrorMessage } from 'components/Form';
import './ListTable.css';

const LoadingMessage = ({ colSpan }) => (
  <tr>
    <td colSpan={colSpan}>Loading...</td>
  </tr>
);

LoadingMessage.propTypes = {
  colSpan: PropTypes.number.isRequired
};

const ErrorMessageRow = ({ colSpan, children }) => (
  <tr>
    <td colSpan={colSpan}>
      <ErrorMessage>{children}</ErrorMessage>
    </td>
  </tr>
);

ErrorMessageRow.propTypes = {
  colSpan: PropTypes.number.isRequired,
  children: PropTypes.string.isRequired
};

export default function ListTable({ children, tableData, error, isLoading }) {
  const { colGroup, header } = tableData;

  const tableBody = useMemo(() => {
    if (isLoading) return <LoadingMessage colSpan={colGroup.length} />;

    if (error)
      return (
        <ErrorMessageRow colSpan={colGroup.length}>
          {error.message}
        </ErrorMessageRow>
      );

    return children;
  }, [isLoading, error, children]);

  return (
    <table className="list-table">
      <colgroup className="list-table__colgroup">
        {colGroup && colGroup.map((width) => <col width={width} />)}
      </colgroup>
      <thead className="list-table__head">
        <tr>{header && header.map((head) => <th>{head}</th>)}</tr>
      </thead>
      <tbody className="list-table__body">{tableBody}</tbody>
      {!isLoading && (
        <tfoot className="list-table__foot">
          <tr>
            <td colSpan={header.length}>
              Hummm, I think this is the end of the list.
            </td>
          </tr>
        </tfoot>
      )}
    </table>
  );
}

ListTable.propTypes = {
  children: PropTypes.string.isRequired,
  tableData: PropTypes.shape({
    colGroup: PropTypes.arrayOf(PropTypes.string).isRequired,
    header: PropTypes.arrayOf(PropTypes.string).isRequired
  }).isRequired,
  error: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired
};
