import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Joi from 'joi-browser';

import { ErrorMessage } from 'components/Form';
import './TextField.css';

export default function TextField({ targetInput }) {
  const { label, type, id, placeholder, state, setState, schema } = targetInput;
  const [validationError, setValidationError] = useState('');

  const validateInputOnBlur = (e) => {
    const result = Joi.validate(e.target.value, schema);
    if (result.error)
      setValidationError(result.error.message.replace('"value"', '👆 '));
    else setValidationError('');
  };

  return (
    <label className="form--text_field" htmlFor={id}>
      {label}
      <input
        className={validationError ? 'invalid' : ''}
        type={type || 'text'}
        id={id}
        value={state}
        onChange={(e) => setState(e.target.value)}
        onBlur={validateInputOnBlur}
        onInvalid={(e) => e.preventDefault()}
        placeholder={placeholder || ''}
      />
      {validationError && <ErrorMessage>{validationError}</ErrorMessage>}
    </label>
  );
}

TextField.propTypes = {
  targetInput: PropTypes.shape({
    label: PropTypes.string.isRequired,
    type: PropTypes.string,
    id: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    state: PropTypes.string.isRequired,
    setState: PropTypes.func.isRequired,
    schema: PropTypes.object.isRequired,
    message: PropTypes.string.isRequired
  }).isRequired
};
