export const USER_LOGIN_START = 'LOGIN_STARTED';
export const USER_LOGIN_SUCCESS = 'LOGIN_SUCCEED';
export const USER_LOGIN_FAIL = 'LOGIN_FAILED';
export const USER_LOGOUT = 'LOGOUT_USER';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';
export const UPDATE_AVATAR_SUCCESS = 'UPDATE_AVATAR_SUCCESS';
export const UPDATE_AVATAR_FAIL = 'UPDATE_AVATAR_FAIL';
export const CATEGORY_FETCH_START = 'CATEGORY_FETCH_START';
export const CATEGORY_FETCH_SUCCESS = 'CATEGORY_FETCH_SUCCESS';
export const CATEGORY_FETCH_FAIL = 'CATEGORY_FETCH_FAIL';
export const CHANNEL_FETCH_START = 'CHANNEL_FETCH_START';
export const CHANNEL_FETCH_SUCCESS = 'CHANNEL_FETCH_SUCCESS';
export const CHANNEL_FETCH_FAIL = 'CHANNEL_FETCH_FAIL';
