import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { FaBars } from 'react-icons/fa';

import { Menu } from 'components';
import { Brand, Account } from './components';

import './Header.css';

const menuOptions = [
  {
    linkTo: '/',
    label: 'Home',
    exact: true
  },
  {
    linkTo: '/forum',
    label: 'Forum'
  },
  {
    linkTo: '/about',
    label: 'About'
  }
];

export default function Header() {
  const { isLogIn } = useSelector((state) => state.user);
  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useState(false);
  const onClick = () => setIsActive(!isActive);

  useEffect(() => {
    const pageClickEvent = (e) => {
      if (
        dropdownRef.current !== null &&
        !dropdownRef.current.contains(e.target)
      ) {
        setIsActive(!isActive);
      }
    };

    // If the item is active (ie open) then listen for clicks
    if (isActive) {
      window.addEventListener('click', pageClickEvent);
    }

    return () => {
      window.removeEventListener('click', pageClickEvent);
    };
  }, [isActive]);

  return (
    <header className="header">
      <Brand data-test="site-brand" />

      <nav className="header__nav">
        <Menu menuOptions={menuOptions} />

        {!isLogIn ? (
          <ul>
            <li>
              <NavLink to="/login">Login</NavLink>
            </li>
            <li>
              <NavLink to="/sign-up">Sign Up</NavLink>
            </li>
          </ul>
        ) : (
          <Account />
        )}
      </nav>

      <button type="button" className="menuBar" onClick={onClick}>
        <FaBars />
      </button>

      <nav
        ref={dropdownRef}
        className={`header__nav__mobile ${isActive ? 'active' : 'inactive'}`}
      >
        <div className="header__nav__mobile__wrapper">
          <Menu menuOptions={menuOptions} />

          {!isLogIn ? (
            <ul className="header__nav__mobile__wrapper__auth">
              <li>
                <NavLink to="/login">Login</NavLink>
              </li>
              <li>
                <NavLink to="/sign-up">Sign Up</NavLink>
              </li>
            </ul>
          ) : (
            <Account />
          )}
        </div>
      </nav>
    </header>
  );
}
