import React from 'react';
import { User } from './components';
import './Profilepage.css';

export default function ProfilePage() {
  return (
    <>
      <section className="profile">
        <User />
      </section>
    </>
  );
}
