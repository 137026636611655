import { combineReducers } from 'redux';

import user from './user.reducer';
import category from './category.reducer';
import channel from './channel.reducer';

export default combineReducers({
  user,
  category,
  channel
});
