import React from 'react';
import PropTypes from 'prop-types';
import './PageBanner.css';

export default function PageBanner({ title, bg }) {
  return (
    <div
      className="page--banner"
      style={{
        backgroundImage: `url(${bg})`
      }}
    >
      <h1>{title}</h1>
    </div>
  );
}

PageBanner.propTypes = PropTypes.string.isRequired;
