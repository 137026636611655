import React from 'react';
import ProfileEdit from './components/ProfileEdit/ProfileEdit';
import PasswordChange from './components/PasswordChange/PasswordChange';

import './ProfileEditPage.css';

export default function ProfileEditPage() {
  return (
    <section className="edit_wrapper">
      <ProfileEdit />
      <PasswordChange />
    </section>
  );
}
