import React from 'react';
import PropTypes from 'prop-types';
import './Title.css';

export default function Title({ children, fontColor }) {
  return (
    <h3 className="my-title" style={{ color: fontColor }}>
      {children}
    </h3>
  );
}

Title.propTypes = {
  children: PropTypes.string.isRequired,
  fontColor: PropTypes.string.isRequired
};
