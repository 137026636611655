import React from 'react';
import './ResetPasswordPage.css';
import { useHistory } from 'react-router-dom';

export default function ResetPasswordFail() {
  const history = useHistory();
  return (
    <section className="reset_password_page">
      <h2 className="reset_password_page--title">Uh oh...</h2>
      <p>Something went wrong, please try again later</p>
      <div className="reset_password_page--statement">
        <button
          type="submit"
          className="form--button-submit"
          onClick={() => history.push('/login')}
        >
          back to login
        </button>
      </div>
    </section>
  );
}
