import { USER_LOGOUT } from 'store/actionTypes';

const logoutAction = () => ({
  type: USER_LOGOUT
});

export default function logoutUser() {
  sessionStorage.clear();

  const callback = (dispatch) => {
    dispatch(logoutAction());
  };

  return callback;
}
