import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import './MainContent.css';

export default function MainContent(props) {
  const { body } = props;
  return <p className="content">{body && parse(body)}</p>;
}

MainContent.propTypes = { body: PropTypes.string.isRequired };
