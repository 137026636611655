import React from 'react';
// eslint-disable-next-line
import {
  FaChild,
  FaSmileWink,
  FaInfinity,
  FaGamepad,
  FaMountain,
  FaCalendarAlt
} from 'react-icons/fa';
import './SpecialPoint.css';

const specialPointDataLeft = [
  {
    id: 1,
    icon: FaChild,
    title: 'crazy people',
    paragraph:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime vitae voluptate iste molestias error commodi sit'
  },
  {
    id: 2,
    icon: FaSmileWink,
    title: 'friednly community',
    paragraph:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime vitae voluptate iste molestias error commodi sit'
  },
  {
    id: 3,
    icon: FaInfinity,
    title: 'infinite imagination',
    paragraph:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime vitae voluptate iste molestias error commodi sit'
  }
];

const specialPointDataRight = [
  {
    id: 1,
    icon: FaGamepad,
    title: 'Playable',
    paragraph:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime vitae voluptate iste molestias error commodi sit'
  },
  {
    id: 2,
    icon: FaMountain,
    title: 'keep challenging',
    paragraph:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime vitae voluptate iste molestias error commodi sit'
  },
  {
    id: 3,
    icon: FaCalendarAlt,
    title: 'regular events',
    paragraph:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime vitae voluptate iste molestias error commodi sit'
  }
];

function SpecialPointLeft() {
  return specialPointDataLeft.map((item) => (
    <div key={item.id} className="special-point">
      <div className="special-point__heading">
        <item.icon />
        <h4>{item.title}</h4>
      </div>
      <p> {item.paragraph} </p>
    </div>
  ));
}

function SpecialPointRight() {
  return specialPointDataRight.map((item) => (
    <div key={item.id} className="special-point">
      <div className="special-point__heading">
        <item.icon />
        <h4>{item.title}</h4>
      </div>
      <p> {item.paragraph} </p>
    </div>
  ));
}

export { SpecialPointLeft, SpecialPointRight };
