import React from 'react';
import './Image.css';

export default function Logo() {
  return (
    <img
      src="https://github.com/TobbyToe/photos2021/blob/gh-pages/Screenshot_51.png?raw=true"
      alt="great phermesia"
      className="mission__image"
    />
  );
}
