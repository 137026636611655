import React, { useState } from 'react';
// import { FaPen } from 'react-icons/fa';
import PropTypes from 'prop-types';
import './Input.css';

export default function Input({ id, label, info, error, onChange }) {
  const [value, setValue] = useState('');

  return (
    <div className="input">
      <label htmlFor={id} className="input--label">
        {label}
        {/* <FaPen /> */}
      </label>
      <input
        className="input--form"
        id={id}
        error={error}
        value={value}
        placeholder={info}
        onChange={(event) => {
          setValue(event.target.value);
          onChange(event);
        }}
      />
    </div>
  );
}

Input.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  info: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired,
  onChange: PropTypes.string.isRequired
};
